<script>
export default {
  mounted(){
    if(localStorage.getItem('token')){
      let type = localStorage.getItem('user_type');
      this.$router.push('/'+type+'/index')
    }else{
        this.$router.replace('/login')
    }
  }
}
</script>