/*
 * @Description: lhy
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-03-14 23:48:20
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-04-02 14:05:56
 */

import maintenance from './maintenance/maintenance.router'       //维保模块 路由
import alarm from       './alarm/alarm.router'                   //报修模块 路由
import rescue from      './rescue/rescue.router'                 //报修模块 路由
import annual_inspection from './annual-inspection/annual-inspection.router'                         //报修模块 路由
import elevator from './elevator/elevator.router'       //维保电梯模块 路由

import user from './user/user.router'       //人员模块 路由

export default{
    routes: [
        ...elevator.routes,
        ...maintenance.routes,
        ...alarm.routes,
        ...rescue.routes,
        ...annual_inspection.routes,
        ...user.routes,
        {
            path: '/maintenance/index',
            name: 'Index',
            title: '主页',
            component: () => import('@/views/maintenance/index.vue')
        },
    ]
}
 