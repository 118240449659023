/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-03-14 23:48:20
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-05-06 11:50:05
 */
export default {
    routes: [
        // 救援
        {
            path: '/maintenance/rescue/list/:id',
            name: 'list',
            title: '救援工单',
            component: () => import('@/views/maintenance/rescue/list.vue')
        },
        {
            path: '/maintenance/rescue/selectHouses',
            name: 'selectHouses',
            title: '搜索楼盘',
            component: () => import('@/views/maintenance/rescue/select_houses.vue')
        },
        {
            path: '/maintenance/rescue/info/:id',
            name: 'info',
            title: '救援接单',
            component: () => import('@/views/maintenance/rescue/info.vue')
        },

        {
            path: '/maintenance/rescue/arrive/:id',
            name: 'arrive',
            title: '救援处理工单的详情',
            component: () => import('@/views/maintenance/rescue/arrive.vue')
        },

    ]
}