/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-01-11 10:31:41
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-05-10 10:30:31
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

import home from '@/views/home.vue'

import Maintenance from './maintenance/index.router'
import Property from './property/index.router'

Vue.use(VueRouter)

const routes = [
  
  {
    path: '/',
    name: 'index',
    redirect:'/home',
    component: home
  }, {
    path: '/home',
    name: 'home',
    title: '首页',
    component: () => import('../views/home.vue')
  }, {
    path: '/login',
    name: 'Login',
    title: '登录页',
    component: () => import('../views/login.vue')
  },
  {
    path: '/apply',
    name: 'Apply',
    title: '申请成为维保人',
    component: () => import('@/views/apply.vue'),
  },
  {
    path: '/choose',
    name: 'Choose',
    title: '选择维保公司',
    component: () => import('@/views/choose.vue'),
  },
  {
    path: '/loading',
    name: 'Loading',
    title: '审核中',
    component: () => import('@/views/loading.vue'),
  },
  {
    path: '/forget',
    name: 'Forget',
    title: '忘记密码',
    component: () => import('@/views/forget.vue'),
  },
  {
    path: '/pswd',
    name: 'Pswd',
    title: '填写新密码',
    component: () => import('@/views/pswd.vue')
  },
  ...Maintenance.routes,
  ...Property.routes,
]
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  routes
})
 

export default router