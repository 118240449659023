/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-03-14 23:48:20
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-04-01 14:25:06
 */
export default {
    routes: [
        // 救援
        {
            path: '/maintenance/user',
            name: 'user',
            title: '我的主页',
            component: () => import('@/views/maintenance/user/user.vue')
        },
        {
            path: '/maintenance/user/calendar',
            name: 'calendar',
            title: '日历',
            component: () => import('@/views/maintenance/user/calendar.vue')
        },
        {
            path: '/maintenance/user/statistics',
            name: 'statistics',
            title: '工作统计',
            component: () => import('@/views/maintenance/user/statistics.vue')
        },
        {
            path: '/maintenance/user/changePassword',
            name: 'change_password',
            title: '修改密码',
            component: () => import('@/views/maintenance/user/change_password.vue')
        },
    ]
}