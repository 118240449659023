/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-03-14 23:48:20
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-03-28 10:54:29
 */
export default {
    routes: [
        {//维保工单列表
            path: '/property/maintenance/list/:id',
            name: 'maintenance_list',
            title: '工单列表',
            component: () => import('@/views/property/maintenance/list.vue')
        },
        {//维保工单列表  物业搜索
            path: '/property/maintenance/search',
            name: 'search',
            title: '物业搜索',
            component: () => import('@/views/property/maintenance/search.vue')
        },
        {//维保工单  详情界面
            path: '/property/maintenance/detail/:id',
            name: 'detail',
            title: '详情',
            component: () => import('@/views/property/maintenance/detail.vue')
        },
        {//维保工单    签名界面
            path: '/property/maintenance/sign/:id',
            name: 'sign',
            title: '签名',
            component: () => import('@/views/property/maintenance/sign.vue')
        },
        {
            path: '/property/maintenance/maintenance-item/:id',
            name: 'item',
            title: '维保项列表',
            component: () => import('@/views/property/maintenance/maintenance-item.vue')
        },
    ]
}