/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-03-14 23:48:20
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-03-17 17:45:41
 */
export default {
    routes: [
        { //电梯列表
            path: '/property/elevator/list/:id',
            name: 'elevator/list',
            title: '电梯列表',
            component: () => import('@/views/property/elevator/list.vue')
        },
        { //电梯列表 搜索楼盘
            path: '/property/elevator/houses-select',
            name: 'houses-select',
            title: '电梯楼盘搜索页',
            component: () => import('@/views/property/elevator/houses-select')
        },
        {//电梯 详情
            path: '/property/elevator/:id',
            title: '相关电梯的硬件数据',
            component: () => import('@/views/property/elevator.vue'),
            redirect: '/property/elevator/:id/basic-info',
            children: [
                {
                    path: 'basic-info',
                    name: 'basic-info',
                    title: '基本信息',
                    component: () => import('@/views/property/elevator/elevator-basic-info.vue')
                },
                {
                    path: 'register-info',
                    name: 'register-info',
                    title: '登记信息',
                    component: () => import('@/views/property/elevator/elevator-register-info.vue')
                },
                {
                    path: 'maintenance',
                    name: 'maintenance',
                    title: '维保记录',
                    component: () => import('@/views/property/elevator/elevator-maintenance.vue')
                },
                {
                    path: 'alarm',
                    name: 'alarm',
                    title: '维修记录',
                    component: () => import('@/views/property/elevator/elevator-alarm.vue')
                },
                {
                    path: 'annual-inspection',
                    name: 'AnnualInspection',
                    title: '电梯年检记录',
                    component: () => import('@/views/property/elevator/elevator-annual-inspection.vue')
                },
                {
                    path: 'rescue',
                    name: 'Rescue',
                    title: '救援记录',
                    component: () => import('@/views/property/elevator/elevator-rescue.vue')
                },
                {
                    path: 'running-status',
                    name: 'running-status',
                    title: '实时状态',
                    component: () => import('@/views/property/elevator/elevator-running-status.vue')
                },
                {
                    path: 'parts',
                    name: 'Parts',
                    title: '部件管理',
                    component: () => import('@/views/property/elevator/elevator-parts.vue')
                },
                {
                    path: 'monitor',
                    name: 'Monitor',
                    title: '视频监控',
                    component: () => import('@/views/property/elevator/elevator-monitor.vue')
                },
                {
                    path: 'detail',
                    name: 'Detail',
                    title: '详情',
                    component: () => import('@/views/property/dt-detail.vue')
                },

            ]
        }
    ]
}