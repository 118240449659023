/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-03-14 23:48:20
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-03-28 10:59:30
 */
export default {
    routes: [
        // 报警维修
        {
            path: '/property/alarm/list/:id',
            name: 'list',
            title: '报警维修',
            component: () => import('@/views/property/alarm/list.vue')
        },
        {
            path: '/property/alarm/search',
            name: 'search',
            title: '物业公司搜索页',
            component: () => import('@/views/property/alarm/search.vue')
        },
        {
            path: '/property/alarm/detail/:id',
            name: 'detail',
            title: '工单详情',
            component: () => import('@/views/property/alarm/detail.vue')
        },
        {
            path: '/property/alarm/sign/:id',
            name: 'sign',
            title: '签名',
            component: () => import('@/views/property/alarm/sign.vue')
        },
        { //零部件列表
            path: '/property/alarm/part-list/:id',
            name: 'part-list',
            title: '零部件更换维修',
            component: () => import('@/views/property/alarm/part-list.vue')
        },
        {//零部件添加
            path: '/property/alarm/part-add/:id',
            name: 'part-add',
            title: '零部件添加',
            component: () => import('@/views/property/alarm/part-add.vue')
        },
        {//零部件修改
            path: '/property/alarm/part-edit/:id',
            name: 'part-edit',
            title: '零部件编辑',
            component: () => import('@/views/property/alarm/part-edit.vue')
        }
    ]
}