/*
 * @Description: 公共方法
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-03-19 14:56:58
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-04-01 11:06:32
 */
export default { //公开

    /*
        设置list列表项的值
    */
    setList(list,data){
        list.forEach((item, i) => {
            for (var key in data) {
                if (item.key == key) {
                    list[i].value = data[key];
                }
            }
        })
        return list;
    },
     
    /**
     * 设置cookie
     **/
    setCookie(name, value, day) {
        let date = new Date();
        date.setDate(date.getDate() + day);
        document.cookie = name + '=' + value + ';expires=' + date;
    },

    /**
     * 获取cookie
     **/
    getCookie(name) {
        let reg = RegExp(name + '=([^;]+)');
        let arr = document.cookie.match(reg);
        if (arr) {
            return arr[1];
        } else {
            return '';
        }
    },

    /**
     * 删除cookie
     **/
    delCookie(name) {
        setCookie(name, null, -1);
    }
}
