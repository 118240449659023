/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-03-14 23:48:20
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-03-28 11:00:08
 */
export default {
    routes: [
        // 救援
        {
            path: '/property/rescue/list/:id',
            name: 'list',
            title: '救援工单',
            component: () => import('@/views/property/rescue/list.vue')
        },
        {
            path: '/property/rescue/search',
            name: 'search',
            title: '物业公司搜索页',
            component: () => import('@/views/property/rescue/search.vue')
        },
        {
            path: '/property/rescue/detail/:id',
            name: 'detail',
            title: '工单详情',
            component: () => import('@/views/property/rescue/detail.vue')
        },
        {
            path: '/property/rescue/sign/:id',
            name: 'sign',
            title: '签名',
            component: () => import('@/views/property/rescue/sign.vue')
        },
    ]
}