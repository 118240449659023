/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-03-14 23:48:20
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-05-06 11:49:44
 */
export default {
    routes: [
        // 电梯年检
        {
            path: '/maintenance/annual-inspection/list/:id',
            name: 'list',
            title: '电梯年检列表',
            component: () => import('@/views/maintenance/annual-inspection/list.vue')
        },
        {
            path: '/maintenance/annual-inspection/selectHouses',
            name: 'selectHouses',
            title: '搜索楼盘',
            component: () => import('@/views/maintenance/annual-inspection/select_houses.vue')
        },
        {
            path: '/maintenance/annual-inspection/info/:id',
            name: 'info',
            title: '年检接单',
            component: () => import('@/views/maintenance/annual-inspection/info.vue')
        },
        
        {
            path: '/maintenance/annual-inspection/arrive/:id',
            name: 'arrive',
            title: '年检处理工单的详情',
            component: () => import('@/views/maintenance/annual-inspection/arrive.vue')
        },
        
    ]
}