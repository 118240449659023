/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-03-14 23:48:20
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-05-06 11:51:55
 */
export default {
    routes: [
        // 报警维修
        {
            path: '/maintenance/alarm/list/:id',
            name: 'list',
            title: '报警维修',
            component: () => import('@/views/maintenance/alarm/list.vue')
        },
        {
            path: '/maintenance/alarm/selectHouses',
            name: 'selectHouses',
            title: '搜索楼盘',
            component: () => import('@/views/maintenance/alarm/select_houses.vue')
        },
        {
            path: '/maintenance/alarm/info/:id',
            name: 'info',
            title: '报警维修详情',
            component: () => import('@/views/maintenance/alarm/info.vue')
        },
    
        {
            path: '/maintenance/alarm/arrive/:id',
            name: 'arrive',
            title: '处理工单的详情',
            component: () => import('@/views/maintenance/alarm/arrive.vue')
        },
      
        {
            path: '/maintenance/alarm/part-list/:id',
            name: 'part-list',
            title: '零部件更换维修',
            component: () => import('@/views/maintenance/alarm/part-list.vue')
        },
        {
            path: '/maintenance/alarm/part-add/:id',
            name: 'part-add',
            title: '零部件添加',
            component: () => import('@/views/maintenance/alarm/part-add.vue')
        },
        {
            path: '/maintenance/alarm/part-edit/:id',
            name: 'part-edit',
            title: '零部件编辑',
            component: () => import('@/views/maintenance/alarm/part-edit.vue')
        }
    ]
}