/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-03-14 23:48:20
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-05-06 11:50:26
 */
export default {
    routes: [
        {//维保工单列表
            path: '/maintenance/maintenance/list/:id',
            name: 'maintenance_list',
            title: '工单列表',
            component: () => import('@/views/maintenance/maintenance/list.vue')
        },
        {
            path: '/maintenance/maintenance/selectHouses',
            name: 'selectHouses',
            title: '搜索楼盘',
            component: () => import('@/views/maintenance/maintenance/select_houses.vue')
        },
        {
            path: '/maintenance/maintenance/info/:id',
            name: 'info',
            title: '接单工单',
            component: () => import('@/views/maintenance/maintenance/info.vue')
        }, 
        {
            path: '/maintenance/maintenance/arrive/:id',
            name: 'arrive',
            title: '处理工单的详情',
            component: () => import('@/views/maintenance/maintenance/arrive.vue')
        },
        
        {
            path: '/maintenance/maintenance/item-list/:id',
            name: 'item-list',
            title: '维保项列表',
            component: () => import('@/views/maintenance/maintenance/item_list.vue')
        },
    ]
}