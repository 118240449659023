/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-03-14 23:48:23
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-03-28 11:03:48
 */

import alarm             from './alarm/alarm.router'             //引入 物业 维修模块 路由
import rescue            from './rescue/rescue.router'           //引入 物业 救援模块 路由
import maintenance       from './maintenance/maintenance.router' //引入 物业 维保模块 路由
import annual_inspection from './annual-inspection/annual-inspection.router' //引入 物业年检模块 路由
import elevator          from './elevator/elevator.router'       //引入 物业电梯模块 路由

export default {
    routes: [
        ...alarm.routes,
        ...rescue.routes,
        ...maintenance.routes,
        ...annual_inspection.routes,
        ...elevator.routes,
        {
            path: '/property/index',
            name: 'Index',
            title: '主页',
            component: () => import('@/views/property/index.vue')
        },
        {
            path: '/property/pswds',
            name: 'Pswds',
            title: '修改密码',
            component: () => import('@/views/property/pswds.vue')
        },
        {
            path: '/property/my',
            name: 'My',
            title: '我的',
            component: () => import('@/views/property/my.vue')
        },
        {
            path: '/property/line',
            name: 'Line',
            title: '折线图',
            component: () => import('@/views/property/line.vue')
        },
        {
            path: '/property/Calendar',
            name: 'Calendar',
            title: '日历',
            component: () => import('@/views/property/Calendar.vue')
        },
        {
            path: '/property/flv',
            name: 'Flv',
            title: '监控',
            component: () => import('@/views/property/flv.vue')
        },
        {
            path: '/property/step',
            name: 'Step',
            title: '发布工单',
            component: () => import('@/views/property/Step.vue')
        },
        {
            path: '/property/add-alarm',
            name: 'add-alarm',
            title: '发布维修工单',
            component: () => import('@/views/property/add-alarm.vue')
        },
        {
            path: '/property/add-rescue',
            name: 'add-rescue',
            title: '发布救援工单',
            component: () => import('@/views/property/add-rescue.vue')
        }
]}