/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-01-11 10:31:41
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-05-07 10:29:34
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import common from './common/common'


Vue.use(Vant);
Vue.config.productionTip = false
Vue.prototype.$common = common

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')